import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { HttpService } from "src/services/http.service";
import { ContentResponse, ContentScreenComponent, FFWDContent } from "../../secure/content/content.component";

@Component({
  selector: "app-unsecure-content",
  imports: [ContentScreenComponent, AsyncPipe],
  templateUrl: "./unsecure-content.component.html",
  styleUrl: "./unsecure-content.component.less",
})
export class UnsecureContentComponent implements OnInit {
  private route: string;
  private http: HttpService;
  private router: Router;

  public content: FFWDContent | null;
  public isLoading$: BehaviorSubject<boolean>;

  public constructor() {
    this.http = inject(HttpService);
    this.router = inject(Router);
    this.route = inject(ActivatedRoute)
      .snapshot.url.map((f) => f.path)
      .join("/");

    this.content = null;
    this.isLoading$ = new BehaviorSubject(false);
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.isLoading$.next(true);
      const response = await this.http.retrieve<ContentResponse>(`${ROUTES_CONFIG.unsecureContenturl}/${this.route}`);
      this.content = JSON.parse(response[0].content);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading$.next(false);
    }

    if (!this.content) {
      this.router.navigate(["/"]);
    }
  }
}
