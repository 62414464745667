import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

import { DefaultComponent } from "../default.component";

@Component({
  selector: "app-screen-auth",
  imports: [CommonModule, RouterOutlet],
  templateUrl: "./auth.component.html",
  styleUrl: "./auth.component.less",
})
export class AuthScreenComponent extends DefaultComponent {}
